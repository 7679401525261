<template>
  <div class="container">
    <a-form-model
      style="margin-top: 3%"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 12 }"
      :model="form"
      :rules="rules"
      ref="ruleForm"
    >
      <a-form-model-item label="产品型号" prop="identifier">
        <a-input v-model="form.identifier" placeholder="请输入产品型号" />
      </a-form-model-item>
      <a-form-model-item label="产品名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入产品名称" />
      </a-form-model-item>
      <a-form-model-item label="关联物模型">
        <a-button @click="handleAddBtn">添加</a-button>
        <a-table :data-source="form.productDtos" size="small">
          <a-table-column align="center" data-index="productId" key="productId" title="产品ID"></a-table-column>
          <a-table-column align="center" data-index="name" key="name" title="产品名称"></a-table-column>
          <a-table-column align="center" data-index="type" key="type" title="产品类型">
            <template slot-scope="scope"> {{ productTypes(scope) }}</template>
          </a-table-column>
          <a-table-column align="center" title="操作" data-index="operation" key="operation">
            <template slot-scope="text, record">
              <a-button @click="jumpToDetail(record)" type="link" style="color: #70b603">详情</a-button>
              <a-button @click="handleDeleteProduct(record)" type="link" style="color: #d9001b">删除</a-button>
            </template>
          </a-table-column>
        </a-table>
        <div style="margin-top: 3%">
          <a-button type="primary" @click="handleSubmit"> 保存 </a-button>
          <a-button style="margin-left: 3%" @click="goBack">返回</a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
    <ProductTransferModal ref="productTransferModal" @selectedProduct="handleSelectedProduct" />
  </div>
</template>
<script>
import ProductTransferModal from './components/ProductTransferModal';

export default {
  name: 'ProductModelCreator',
  components: { ProductTransferModal },
  async activated() {
    await this.getMetaData();
  },
  data() {
    return {
      form: {
        identifier: '',
        name: '',
        productDtos: [],
      },
      metaData: {},
      rules: {
        identifier: [{ required: true, message: '请输入产品型号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
      },
    };
  },
  methods: {
    dataInit() {
      this.form = {
        identifier: '',
        name: '',
        productDtos: [],
      };
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$apiManager.productModel
            .createProductModel({ ...this.form, productIds: this.form.productDtos.map((product) => product.productId) })
            .then(() => {
              this.$message.success('创建成功');
              this.goBack();
              this.dataInit();
            });
        }
      });
    },
    handleAddBtn() {
      this.$refs.productTransferModal.openModal(this.metaData, this.form.productDtos);
    },
    handleSelectedProduct(products) {
      this.form.productDtos = products;
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    handleDeleteProduct(product) {
      this.form.productDtos = this.form.productDtos.filter((p) => p.productId !== product.productId);
    },
    jumpToDetail(product) {
      this.$router.push({ name: 'productDetail', params: { id: product.productId } });
    },
    goBack() {
      this.dataInit();
      this.$router.back();
      this.$refs.ruleForm.resetFields();
    },
  },
  computed: {
    productTypes() {
      return (type) => {
        const typeName = this.metaData.productTypes.find((md) => md.value === type);
        return typeName ? typeName.name : '';
      };
    },
  },
};
</script>
<style lang="less" scoped></style>
